/**
 * Session Slider
 * @description : Swiper for Session sliders.
 */

window.setUpSessionSlider = function (sessionSliderEl) {

    var swiperEl = sessionSliderEl.querySelector('.js-session-slider__swiper');

    if (swiperEl) {
        var scrollbarEl = sessionSliderEl.querySelector('.js-session-slider__scrollbar');
        new Swiper(swiperEl, {
            freeMode: true,
            freeModeMomentumRatio: 0.3,
            slidesPerView: 2,
            direction: 'horizontal',
            loop: false,
            breakpoints: {
                [window.breakpoints['sm']]: {
                    scrollbar: {
                        dragSize: 200
                    },
                    slidesPerView: 3
                },
                [window.breakpoints['md']]: {
                    scrollbar: {
                        dragSize: 400
                    },
                    slidesPerView: 4
                },
                [window.breakpoints['lg']]: {
                    slidesPerView: 5,
                    spaceBetween: 16,
                },
                [window.breakpoints['xl']]: {
                    slidesPerView: 6,
                    spaceBetween: 32,
                }
            },
            scrollbar: {
                el: scrollbarEl,
                draggable: true,
                dragClass: 'js-session-slider__scrollbar-drag',
                dragSize: 100
            }
        });
    }

    return swiperEl;

};

(function () {

    var sessionSliderEls = document.querySelectorAll('.js-session-slider');
    sessionSliderEls.forEach(function (sessionSliderEl) {
        window.setUpSessionSlider(sessionSliderEl);
    });

})();